import React from 'react'
import { Row } from 'reactstrap'
import Clipboard from 'react-clipboard.js'

import './socialBar.scss'

import Twitter from '../../assets/icons/icon-twitter.svg'
import LinkedIn from '../../assets/icons/icon-linkedin.svg'
import Facebook from '../../assets/icons/icon-facebook.svg'
import Instagram from '../../assets/icons/icon-instagram.svg'
import CopyLink from '../../assets/icons/icon-copy-link.svg'

const SocialBar = ({ links, id }) => {
	const copyStyle = {
		border: 'none',
		background: 'transparent',
	}

	const socialLink = socialMedia => {
		switch (socialMedia) {
			case 'facebook':
				return `https://www.facebook.com/sharer/sharer.php?u=https://api.carepostcard.com/og/${id}`
			case 'twitter':
				return `https://twitter.com/intent/tweet?url=https://api.carepostcard.com/og/${id}`
			case 'linkedin':
				return `http://www.linkedin.com/shareArticle?url=https://api.carepostcard.com/og/${id}`
			default:
				return ''
		}
	}

	if (id) {
		return (
			<Row>
				<a href={socialLink('twitter')} target='_blank' rel='noopener noreferrer'>
					<img src={Twitter} alt='' className='social-icon' />
				</a>
				<a href={socialLink('linkedin')} target='_blank' rel='noopener noreferrer'>
					<img src={LinkedIn} alt='' className='social-icon' />
				</a>
				<a href={socialLink('facebook')} target='_blank' rel='noopener noreferrer'>
					<img src={Facebook} alt='' className='social-icon' />
				</a>
				<a target='_blank' rel='noopener noreferrer'>
					<Clipboard style={copyStyle} data-clipboard-text={`https://www.carepostcard.com/detail/?id=${id}`}>
						<img src={CopyLink} alt='' className='social-icon' title='Copy Link' />
					</Clipboard>
				</a>
			</Row>
		)
	} else if (links) {
		return (
			<Row>
				{links.twitterUrl && (
					<a href={links.twitterUrl} target='_blank' rel='noopener noreferrer'>
						<img src={Twitter} alt='' className='social-icon' />
					</a>
				)}

				{links.linkedinUrl && (
					<a href={links.linkedinUrl} target='_blank' rel='noopener noreferrer'>
						<img src={LinkedIn} alt='' className='social-icon' />
					</a>
				)}

				{links.facebookUrl && (
					<a href={links.facebookUrl} target='_blank' rel='noopener noreferrer'>
						<img src={Facebook} alt='' className='social-icon' />
					</a>
				)}

				{links.instagramUrl && (
					<a href={links.instagramUrl} target='_blank' rel='noopener noreferrer'>
						<img src={Instagram} alt='' className='social-icon' />
					</a>
				)}
			</Row>
		)
	} else {
		return (
			<Row>
				<a href='' target='_blank' rel='noopener noreferrer'>
					<img src={Twitter} alt='' className='social-icon' />
				</a>

				<a href='' target='_blank' rel='noopener noreferrer'>
					<img src={LinkedIn} alt='' className='social-icon' />
				</a>

				<a href='' target='_blank' rel='noopener noreferrer'>
					<img src={Facebook} alt='' className='social-icon' />
				</a>
				<a target='_blank' rel='noopener noreferrer'>
					<Clipboard style={copyStyle} data-clipboard-text={`https://www.carepostcard.com/detail/?id=${id}`}>
						<img src={CopyLink} alt='' className='social-icon' title='Copy Link' />
					</Clipboard>
				</a>
			</Row>
		)
	}
}

export default SocialBar
