import React, { useEffect, useState } from 'react'
import { Card, Button, Col, Row, Container, CardText } from 'reactstrap'
import { navigate } from 'gatsby'
import { MdLocationOn } from 'react-icons/md'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
// import moment from 'moment'
import axios from 'axios'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SocialBar from '../../components/SocialBar/socialBar'

const DetailPage = () => {
	const [cardData, setCardData] = useState({})
	const [heart, setHeart] = useState(false)
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		async function getCardData() {
			try {
				const params = new URLSearchParams(window.location.search)
				const cardId = params.get('id')

				const cardResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/id/${cardId}`)

				if (cardResponse.data.cpc) {
					setCardData(cardResponse.data.cpc)
					setLoaded(true)
				} else {
					navigate('/404')
				}
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		getCardData()
	}, [])

	async function toggleHeart() {
		setHeart(!heart)
	}

	// const timePast = posted => {
	// 	return moment(posted).fromNow()
	// }

	async function navigateOrg() {
		navigate(`/org/?id=${cardData.organizationId}`)
	}

	async function goBack() {
		window.history.back()
	}

	function formatBody() {
		return {
			__html: cardData.body.replace(
				/(^|\s)(#)([a-z\d-]+)/gi,
				`$1<a href=${`/hashtag/?hashtag=$3`} alt=''><span class='detail-card-hashtag'>$2$3</span></a>`
			),
		}
	}

	const getLocationAddress = () => {
		if (cardData.tempCity || cardData.tempState) {
			return `${formatToTitleCase(cardData.tempCity)}, ${cardData.tempState.toUpperCase()}`
		} else if (cardData.city || cardData.state) {
			return `${formatToTitleCase(cardData.city)}, ${cardData.state.toUpperCase()}`
		} else {
			return 'No location attached'
		}
	}

	const formatToTitleCase = str => {
		if (str) {
			str = str.toLowerCase()
			str = str.split(' ')

			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
			}

			return str.join(' ')
		}
	}

	return (
		<Layout>
			<SEO title='Carepostcard' description='Carepostcard' />
			{loaded && (
				<Container className='pb-5'>
					<Row className='justify-content-around'>
						<Button onClick={() => goBack()} className='detail-card-back-button'>
							Back
						</Button>
					</Row>
					<Row className='justify-content-around'>
						<Card className='detail-card'>
							<Col>
								<Row>
									<Col>
										<Row>
											<img src={cardData.photoUrl} alt='' className='detail-card-image' />
											{cardData.artistName && (
												<span className='detail-card-image-caption'>
													Photo by{' '}
													<a
														href={`https://www.unsplash.com/${cardData.artistHandle}?utm_source=carepostcard&utm_medium=referral`}
														target='_blank'
														rel='noopener noreferrer'
													>
														{cardData.artistName}
													</a>{' '}
													on{' '}
													<a
														href='https://www.unsplash.com?utm_source=carepostcard&utm_medium=referral'
														target='_blank'
														rel='noopener noreferrer'
													>
														Unsplash
													</a>
												</span>
											)}
										</Row>
									</Col>
								</Row>
								<Row className='detail-card-body'>
									<Row className=''>
										<Col xs={1}>
											<MdLocationOn className='detail-card-map-icon ml-2' />
										</Col>
										<Col xs={10}>
											<Button onClick={() => navigateOrg()} className='detail-card-location px-3 pt-0 ml-3'>
												<Row className='detail-card-title'>{cardData.name ? cardData.name : 'No location attached'}</Row>
												<Row className='detail-card-subtitle'>{getLocationAddress()}</Row>
											</Button>
										</Col>
									</Row>
									<Row className='recip-time-row'>
										<span className='detail-card-recipient'>
											To: {cardData.addressedTo ? cardData.addressedTo : 'Your Amazing Team'}
										</span>
										<span className='detail-card-timestamp '>
											{/* {timePast(cardData.postedOn)} */}
											&nbsp;
										</span>
									</Row>
									<CardText className='detail-card-text' dangerouslySetInnerHTML={formatBody()}></CardText>
								</Row>
								<Row className='detail-card-footer'>
									<Button hidden onClick={() => toggleHeart()} className='detail-card-likes'>
										{heart ? (
											<AiFillHeart className='detail-card-heart-filled' />
										) : (
											<AiOutlineHeart className='detail-card-heart-unfilled' />
										)}{' '}
										103 likes
									</Button>
									<SocialBar id={cardData.id} />
								</Row>
							</Col>
						</Card>
					</Row>
				</Container>
			)}
		</Layout>
	)
}

export default DetailPage
